import { Formik } from 'formik';
import React, { memo, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from '../../components/shared/Input';
import ModalEvents from '../../constants/ModalEvents';
import { getFieldProps } from '../../utils';
import DataModal from '../DataModal';

const initialValues = {
  trainingTopic: '',
  score: '',
  pickedAt: '',
  credentialNumber: '',
  expAt: '',
};

const InformalEducationModal = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const schema = Yup.object().shape({
    trainingTopic: Yup.string().required(t('shared.forms.validation.required')),
    score: Yup.string(),
    pickedAt: Yup.date(),
    credentialNumber: Yup.string(),
    expAt: Yup.date(),
  });

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(formik) => (
        <DataModal
          name={t('builder.sections.informalEducation')}
          path="informalEducation.items"
          event={ModalEvents.INFORMAL_EDUCATION_MODAL}
        >
          <div className="grid grid-cols-2 gap-8">
            <Input
              label={t('builder.informalEducation.trainingTopic')}
              className="col-span-2"
              placeholder="Fullstack Developer"
              {...getFieldProps(formik, schema, 'trainingTopic')}
            />

            <Input
              label={t('builder.informalEducation.score')}
              className="col-span-2"
              placeholder="450"
              {...getFieldProps(formik, schema, 'score')}
            />

            <Input
              type="date"
              label={t('builder.informalEducation.pickupAt')}
              placeholder="6th August 208"
              {...getFieldProps(formik, schema, 'pickupAt')}
            />

            <Input
              type="date"
              label={t('builder.informalEducation.expAt')}
              placeholder="6th August 208"
              {...getFieldProps(formik, schema, 'expAt')}
            />

            <div className="flex items-center col-span-2">
              <input
                type="checkbox"
                id="checkbox"
                value={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                  formik.setFieldValue('pickupAt', undefined);
                }}
              />
              <label htmlFor="checkbox" className="ml-2">
                Tidak ada kadaluarsa
              </label>
            </div>

            <Input
              label={t('builder.informalEducation.credentialNumber')}
              className="col-span-2"
              placeholder="123008233622"
              {...getFieldProps(formik, schema, 'credentialNumber')}
            />
          </div>
        </DataModal>
      )}
    </Formik>
  );
};

export default memo(InformalEducationModal);
