import React, { memo, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// import Input from '../components/shared/Input';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';
import { formatIdrCurrency } from '../utils';
import BaseModal from './BaseModal';
import axios from 'axios';

const ReviewModal = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [price, setPrice] = useState(0);
  const [newPrice, setNewPrice] = useState(0);
  // const [referal, setReferal] = useState('');
  const [cvPrice, setCvPrice] = useState(50000);
  const [isReferal, setIsReferal] = useState(false);
  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.REVIEW_MODAL, (payload) => {
      setOpen(true);
      setPrice(formatIdrCurrency(70000));
      setCvPrice(70000);
      setNewPrice(0);
      setIsReferal(false);
      setData(payload);
    });

    return () => unbind();
  }, [emitter, events]);

  // const handleReferal = (e) => {
  //   setReferal(e.target.value);
  // };

  // const handleSubmit = () => {
  //   if (referal === 1234) {
  //     setIsReferal(true);
  //     setNewPrice(formatIdrCurrency(25000));
  //     setCvPrice(25000);
  //   } else {
  //     setIsReferal(false);
  //     setCvPrice(50000);
  //   }
  // };

  const handlePayment = () => {
    let firstName;
    let lastName;

    if (!user.isAnonymous) {
      [firstName, lastName] = user.displayName.split(' ');
    }

    
    const params = {
      productPrice: cvPrice,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: user.email,
    };
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    };

    

    fetch (
      'https://flw1lgllfa.execute-api.ap-southeast-1.amazonaws.com/v1/token',
      requestOptions,
      )
      .then((response) => response.json())
      .then((result) => {
        snap
          .pay(result.transactionToken, {
            onPending: function(result) {

              /* Update Sheets */
              const resumeParams = {
                "paymentId": result.order_id,
                "id": "g346l8",
                "result": result
              }
      
              fetch (
                'http://localhost:3000/v1/updateSheets',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  
                  body: JSON.stringify(resumeParams),
                }
              )

              /* Update Drive */
              let resumeFile = {
                id: data.id,
                type: "single"
              }

              axios
                .post(
                'http://localhost:3000/v1/printResume',
                resumeFile,
                {
                  headers: { 'Content-Type': 'application/json' }, 
                }
              )
              .then((response) => {
                const resumeParams = {
                  "id": data.id,
                  "body": response.data
                }

                fetch(
                  'http://localhost:3000/v1/uploadResume',
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },

                    body: JSON.stringify(resumeParams)
                  })
              })

            }
          })
        setOpen(false);
      })
      .catch(() => toast.error('error connect to payment'));
  };

  const handleRadio = (e) => {
    setCvPrice(e.target.value);
    setPrice(formatIdrCurrency(e.target.value));
  };

  return (
    <BaseModal hideActions state={[open, setOpen]} title="Checkout Review">
      <div className="text-2xl mb-7">{data.name}</div>
      <div className="text-xl font-semibold mb-3">
        {t('dashboard.reviewTime')}
      </div>
      {/* <div onChange={handleRadio}> */}
      <div className="flex items-center mr-4 mb-4 border">
        <input
          className="m-4"
          id="radio1"
          type="radio"
          value={70000}
          name="price"
          defaultChecked
          onChange={handleRadio}
        />
        <label htmlFor="radio1" className="cursor-pointer container">
          3 {t('dashboard.day')}
        </label>
      </div>
      <div className="flex items-center mr-4 mb-4 border">
        <input
          className="m-4"
          id="radio2"
          type="radio"
          value={50000}
          name="price"
          onChange={handleRadio}
        />
        <label htmlFor="radio2" className="cursor-pointer container">
          7 {t('dashboard.day')}
        </label>
      </div>
      <br />
      <hr />
      <br />
      {/* </div> */}
      {/* <div className="flex flex-row gap-4">
        <Input
          label="referal code"
          className="mb-8 w-5/6"
          placeholder="Input referal code"
          // onChange={handleReferal}
          disabled
        />
        <div className="mt-6 inline-block">
          <Button>submit</Button>
        </div>
      </div> */}
      <div className="text-2xl mb-7">
        Total price:{' '}
        <text
          className={`${
            isReferal ? 'text-xl text-gray-400 line-through mr-3' : null
          }`}
        >
          Rp {price}{' '}
        </text>{' '}
        <text className={`${isReferal ? null : 'hidden'}`}> Rp {newPrice}</text>
      </div>
      <div className="flex justify-center mt-5 -mb-10">
        <Button onClick={handlePayment}>Choose Payment</Button>
      </div>
    </BaseModal>
  );
};

export default memo(ReviewModal);
