import download from 'downloadjs';
import { clone } from 'lodash';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPrint } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import Button from '../../components/shared/Button';
import ModalContext from '../../contexts/ModalContext';
import { useSelector } from '../../contexts/ResumeContext';
import { b64toBlob } from '../../utils';
import BaseModal from '../BaseModal';

const ExportModal = () => {
  const state = useSelector();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoadingSingle, setLoadingSingle] = useState(false);
  const [isLoadingMulti, setLoadingMulti] = useState(false);

  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.EXPORT_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleOpenPrintDialog = () => {
    if (typeof window !== `undefined`) {
      window && window.print();
    }
  };

  const handleDownload = (isSinglePDF) => {
    isSinglePDF ? setLoadingSingle(true) : setLoadingMulti(true);

    toast('Downloading the CV for you, please wait!');
    let content = {
      id: state.id,
      type: '',
    };
    if (isSinglePDF == true){
      content.type = 'single';
    }
    else{
      content.type = 'multi';
    }

    axios
      .post(
        'https://opykx6wgj0.execute-api.ap-southeast-1.amazonaws.com/v1/printResume',
        content,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
      .then((response) => {
        if(isSinglePDF == true){
          setLoadingSingle(false);
        }
        else{
          setLoadingMulti(false);
        }
        const blob = b64toBlob(response.data, 'application/pdf');
        download(blob, `AkselResume-${state.id}.pdf`, 'application/pdf');
      });
  };

  const handleExportToJson = () => {
    const backupObj = clone(state);
    delete backupObj.id;
    delete backupObj.user;
    delete backupObj.name;
    delete backupObj.createdAt;
    delete backupObj.updatedAt;
    const data = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(backupObj, null, '\t'),
    )}`;
    download(data, `RxResume-${state.id}.json`, 'text/json');
  };

  return (
    <BaseModal
      hideActions
      state={[open, setOpen]}
      title={t('builder.actions.export.heading')}
    >
      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t('modals.export.printDialog.heading')}
        </h5>

        <p className="leading-loose">{t('modals.export.printDialog.text')}</p>

        <Button icon={FaPrint} className="mt-5" onClick={handleOpenPrintDialog}>
          {t('modals.export.printDialog.button')}
        </Button>
      </div>

      <hr className="my-8" />

      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t('modals.export.downloadPDF.heading')}
        </h5>

        <p className="leading-loose">{t('modals.export.downloadPDF.text')}</p>

        <div className="mt-5 mb-4">
          {/* <p className="text-lg italic text-red-400 font-bold mb-3">Exporting PDF feature is under maintenance</p> */}
          <div className="flex">
            <Button
              isLoading={isLoadingSingle}
              onClick={() => handleDownload(true)}
            >
              {t('modals.export.downloadPDF.buttons.single')}
            </Button>
            <Button
              className="ml-8"
              isLoading={isLoadingMulti}
              onClick={() => handleDownload(false)}
            >
              {t('modals.export.downloadPDF.buttons.multi')}
            </Button>
          </div>
        </div>
      </div>

      <hr className="my-8" />

      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t('modals.export.jsonFormat.heading')}
        </h5>

        <p className="leading-loose">{t('modals.export.jsonFormat.text')}</p>

        <div className="mt-5">
          <Button onClick={handleExportToJson}>
            {t('modals.export.jsonFormat.button')}
          </Button>
          <a id="downloadAnchor" className="hidden">
            {t('modals.export.jsonFormat.button')}
          </a>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(ExportModal);
